<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/behbudiy.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Маҳмудхўжа Беҳбудий<br />(1875 -1919 )</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Маҳмудхўжа Беҳбудий ХХ аср бўсағасидага Туркистон ижтимоий-сиёсий
            ҳаракатчилигининг энг йирик намояндаси, янги давр ўзбек
            маданиятининг асосчисидир. Туркистон жадидларининг тан олинган
            раҳнамоси, мустақил жумҳурият ғоясининг яловбардори, янги мактаб
            ғоясининг назариётчиси ва амалиётчиси, ўзбек драматургиясини бошлаб
            берган биринчи драматург, театрчи, ношир, журналист. У тарихимизнинг
            ғоят оғир ва мураккаб бир даврида яшади. XVI асрдан бошланган
            инқироз ва турғунлик, ўзаро жанжал, маҳаллий уруғчилик низолари
            миллатни ҳолдан тойдирган, имкондан фойдаланиб ўлкани забт этган
            Русия зўр бериб, уни турғун ва тутқун сақлашга уринарди. Мана шундай
            бир шароитда Ватанни бутунлай йўқ бўлиш хавфидан сақлаб қолиш,
            авлодларни эрк ва озодлик, мустақиллик руҳида тарбиялаш, маърифат ва
            тараққиётта бошлаш жадидлар номи билан тарихга кирган Беҳбудий
            бошлиқ фидойилар зиммасига тушди. Туркистонда “Усули жадид”, “Усули
            савтия” номлари билан шуҳрат топган янги мактабни ташкил қилиб,
            Беҳбудий биринчи бўлиб замонавий мактаб ғоясини илгари сурди. Улар
            ўз ҳисобларидан мактаблар очиб, ёш авлодни истиклолга тайёрладилар,
            шеър ва мақолалар, саҳна асарлари орқали миллий онгни шакллантирди.
            Маҳмудхўжа Беҳбудий мана шу Туркистон жадидчилик ҳаракатининг
            асосчиси, бошлаб берувчиси эди. У 1875 йилнинг 19 январида Самарқанд
            яқинидаги Бахшитепа қишлоғида, руҳоний оиласида дунёга келган. Отаси
            Беҳбудхўжа Солиҳхўжа ўғли туркистонлик, Аҳмад Яссавийнинг
            авлодларидан, она томонидан бобоси Ниёзхўжа урганчлик бўлиб, амир
            Шоҳмурод замонида (1785–1800)Самарқандга келиб қолган. 1894 йилда
            отаси, имом-хатиблик билан шуғулланиб келган Беҳбудхўжа вафот этади.
            Ёш Маҳмудхўжа тоғаси қози Муҳаммад Сиддиқ тарбияси ва қарамоғида
            ўсиб вояга етади. Араб сарфу наҳвини кичик тоғаси Мулла Одилдан
            ўрганади. 18 ёшида қозихонада мирзолик қила бошлайди. Ўз устида қунт
            билан ишлаб, шариатнинг юксак мақомлари – қози, муфти даражасигача
            кўтарилади. Ёш Маҳмудхўжа дунёқарашининг шаклланишида Русия
            жадидчилик ҳаракатининг асосчиси Исмоилбек Гаспринскийнинг хизмати
            катта бўлган. У 1892 йилда Туркистондаги мактабларни ислоҳ килиш,
            “усули савтия”ни жорий этиш таклифи билан генерал губернатор Н. О.
            Розенбахга мурожаат этади. Жавоб олмагач, 1893 йилда ўзи Тошкентга
            келди. Самарқанд, Бухорода бўлди. Маҳаллий халқ билан гаплашиб,
            дастлабки янги усул мактабларни очишга муваффақ бўлди. Беҳбудий ўз
            хотираларида устози билан учрашувларини ихлос ва муҳаббат билан
            тилга олади. 1899–1900 йиллардаги ҳаж сафари янги мактаб ҳақидаги
            қарашларини мустаҳкамлайди. Унинг ташаббус ва ғайрати билан 1903
            йилда Самарқанд атрофидаги Ҳалвойи (С. Сиддиқий), Ражабамин (А.
            Шакурий) қишлоқларида янги мактаблар ташкил топди. Адиб бу мактаблар
            учун дарсликлар тузишга киришади. Кетма-кет “Рисолаи асбоби савод”
            (1904), “Рисолаи жуғрофияи умроний” (1905), “Рисолаи жуғрофияи
            Русий” (1905), “Китобатул-атфол” (1908), “Амалиёти ислом” (1908),
            “Тарихи ислом” (1909) каби китоблари пайдо бўлади. Маърифат учун
            биргина мактаб кифоя қилмайди. Замон ва дунё воқеалари билан танишиб
            бормоқ керак. Миллат ва Ватаннинг аҳволидан, кундалик ҳаётидан огоҳ
            бўлмоқ лозим. Миллат учун ойна керак, токи ундан ўз қабоҳатини ҳам
            малоҳатини ҳам кўра олсин. Мана шу эҳтиёж ва зарурат Беҳбудийни
            театр ва матбуотга бошлади. “Падаркуш” драмаси шу тариқа майдонга
            келди. Бироқ унинг дунё кўриши осон кечмади. “Падаркуш” драмаси 1913
            йилдагина босилиб чиқади. Китоб жилдига “Бородино жанги ва
            Россиянинг французлар босқинидан халос бўлишининг юбилей санасига
            бағишланади” деган ёзув билан Тифлис цензурасидан рухсат олинади.
            Босилиб чиққандан кейин ҳам саҳнага қўйиш учун яқин бир йил вақт
            кетади. 1913 йилдан Беҳбудий матбуот ишлари билан шуғулланди.
            Апрелдан “Самарқанд” газетасини чиқара бошлади. Газета туркий ва
            форсий тилларда, ҳафтада икки марта, дастлаб икки, сўнг тўрт
            саҳифада чоп этилган. 45-сони чиқиб, моддий танқислик туфайли
            тўхтаган. Сўнг Беҳбудий шу йилнинг 30 августидан “Ойна” журналини
            чиқара бошлайди. “Ҳафталик, суратлик бу мажалла, асосан, ўзбек
            тилида бўлиб, унда ихчам форсий шеър, мақолалар, русча эълонлар ҳам
            бериб борилади… Кавказ, Татаристон, Эрон, Афғонистон, Ҳиндистон ва
            Туркиягача тарқалар эди… Жадидларнинг севикли журналлари эди…” деб
            ёзган эди Зиё Саид. Беҳбудий шу йиллари нашр ишлари билан қизғин
            шуғулланди. А. В. Пясковский унинг ўз босмахонасида Фитратнинг
            “Баёноти сайёҳи ҳинди” асарини 1913 йилда русчага таржима қилдириб,
            нашр этганини хабар беради… Беҳбудий миллат ўзини англагандагина
            ижтимоий-сиёсий масалаларни бошқалар билан тенг муҳокама эта олади,
            деган фикрда бўлди. Шунинг учун ҳам тарихга алоҳида эътибор берди.
            1917 йилнинг 26 ноябрида Қўқонда ўлка мусулмонларининг IV фавқулодда
            қурултойи иш бошлади. 27 ноябрга ўтар кечаси “Туркистон мухторияти”
            эълон қилинди. Бу мустамлакадан мустакиллик томон қўйилган жиддий ва
            жасоратли қадам эди. Унинг маънавий отаси, шуҳбасиз, Беҳбудий эди.
            Бироқ у Советлар томонидан хоинона бостирилди. 19–20 феврал кунлари
            шаҳар тўпга тутилди. 10 минг туркистонлик ўлдирилди, 180 қишлоққа ўт
            қўйилди. Беҳбудий изтироб билан Самарқандга қайтади. У ерда тура
            олмай, Тошкентга келади. Туркистон рус совет ҳукумати раҳбарлари
            билан музокара олиб боришга уринади. Табиийки, музокаралар натижа
            бермайди. Миллатни, миллий тараққиётни инкор этган шўролар йўли
            алдов ва зўравонликка асосланганини биларди. Шунинг учун ҳам уни
            1906 йилдаёқ ақл ва шариатга зид деб эълон қилган эди. Орзулари
            чил-чил бўлган Беҳбудий 1919 йилнинг баҳорида, 25 мартда паришон
            ҳолда йўлга чиқади ва Шаҳрисабзда қўлга олиниб, тахминан икки ой
            ўтгач, Қаршига келтириб зиндонга ташланди. Бир неча кундан сўнг
            Қарши беги Тоғайбекнинг буйруғи билан зиндон яқинидаги “подшолик
            чорбоғи”да ўлдирилган. Унинг қатли ҳақидаги хабар ўша пайтдаги
            пойтахтимиз Самарқандга роса бир йилдан кейин маълум бўлди. 1920
            йилнинг апрелида бутун Туркистон мотам тутди. 1926–27 йилларда, 11
            йил Қарши шаҳри Беҳбудий номи билан юритилди. Адибнинг номини
            адабийлаштирилиши шунчаки бир ниқоб эди, унинг асл қиёфаси халқдан
            сир сақланди. Қарши шаҳрига унинг номи қўйилган ўша 1926 йилдаёқ
            жадидчиликни аксилинқилобий, аксилшўравий ҳаракат сифатида қоралаш
            кампанияси бошлаб юборилган эди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
